<template>
	<!-- <div class="all" :style="adapter">
		<router-view/>
	</div> -->
	<div class="all" >
		<router-view/>
	</div>
</template>

<script>
	
	export default {
		name: 'layout',
		components:{
		},
		
		computed:{
			
		},
		data() {
			return {
				
				adapter:{
					width: '1920px',
					height: '1080px',
					transform: 'scale(1) translate(-50%, -50%)',
				}
			}
		},
		methods:{
			Debounce: (fn, t) => {
				const delay = t || 500
				let timer
				return function () {
					const args = arguments
					if (timer) {
						clearTimeout(timer)
					}
					const context = this
					timer = setTimeout(() => {
						timer = null
						fn.apply(context, args)
					}, delay)
				}
			},
			setAdapter(){
				const rate1 = window.innerWidth / 1920
				const rate2 = window.innerHeight / 1080
				const rate = rate1 < rate2 ? rate1 : rate2
				// this.adapter.transform="scale("+rate+") translate(-50%, -50%)"
			}
		},
		mounted() {
			this.setAdapter();
			window.onresize = this.Debounce(this.setAdapter, 1000)
		}
		
	}
	
</script>

<style lang="scss" >
	.all{
		margin: 0;
		// transform-origin: 0 0;
		// position: absolute;
		// left: 50%;
		// top: 50%;
		width: 100%;
		height: 100%;
		display: flex;
	}
</style>
