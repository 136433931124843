import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import { doPost,doPostMore ,doGet} from '@/api/apis'
import userEqu from "@/utils/userEqu";

const app=createApp(App);


const tokenname = 'aitalkerClientToken'
app.config.globalProperties.tokenname = tokenname

app.config.globalProperties.$echarts = echarts

// const serverurl = "http://suzamdsltlab002:8090"
// const serverurl = "http://localhost:18886"
const serverurl = "http://127.0.0.1:18893"
// const serverurl = "http://10.72.206.31:9999"
app.config.globalProperties.serverurl = serverurl



const payserver = "http://cop.tallen.cn:18893"
app.config.globalProperties.payserver = payserver


const apipre = '/audioapis'
// const apipre = ''
app.config.globalProperties.apipre = apipre



const payapipre = '/payapis'
// const apipre = ''
app.config.globalProperties.payapipre = payapipre


router.beforeEach((to,from,next)=>{
	
	if((userEqu.isPhone || userEqu.isAndroid) && to.path.indexOf("Mobile") == -1){
		next({
	     path:to.path+"Mobile"
	   });
	}
	
  if(to.matched.some(res=>res.meta.isLogin)){//判断是否需要登录
	// console.log("page token",localStorage['clientToken'])
	// console.log("to",to)
	console.log("app.tokenname",tokenname)
    if (localStorage[tokenname]) {
		var tk = JSON.parse(localStorage[tokenname])
		
		// console.log("tk",tk)
		
		//权限校验
		// tk.token.forEach(function(it){
		// 	if(it.pagepath === to.path){
		// 		next();
		// 	}
		// })
		
		//token校验
		// doPost({ },apipre+'/checkToken').then(response => {
		// 	if(response.code === 1){
		// 		next();
			   
		// 	}else{
		// 	   next({
		// 		 path:"/login"
		// 	   });
		// 	}
		// }).catch(error => {
		 
		//    next({
		// 	 path:"/login"
		//    });
		// })
		
		next();
      
    }else{
      next(
	  // {
   //      path:"/login"
   //    }
	  );
    }
  }else{
    next()
  }
});


app.use(store).use(router).use(ElementPlus).mount('#app')

export default app