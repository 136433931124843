import request from '@/api/request'


export function doPost(data,url) {
  return request({
    url: url,
    method: 'post',
    data
  })
}

export function doPostMore(data,url,responseType) {
  var param = {
    url: url,
    method: 'post',
	responseType:responseType,
    data
  }
  return request(param)
}

export function doGet(data,url) {
  return request({
    url: url,
    method: 'get',
    data
  })
}


