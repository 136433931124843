<template>
	<div id="app">
			<router-view />
	</div>
    
</template>

<style lang="scss">
	html,body{
		margin: 0;
		padding: 0;
		width: 100%;
		height: 100%;
	}
	
	
	.el-table th.gutter{
	    display: table-cell!important;
	}
	#app {
	  font-family: Avenir, Helvetica, Arial, sans-serif;
	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
	  // text-align: center;
	  color: #aaaaff;
	  // width: 1920px;
	  // height: 937px;
	  width: 100%;
	  height: 100%;
	}
	
</style>
