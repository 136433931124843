import {
	createRouter,
	createWebHistory
} from 'vue-router'
import layout from '@/views/layout/layout'
import home from '@/views/home/home'
import homeMobile from '@/views/home/homeMobile'

const routes = [
	// {
	//   path: '/login',
	//   component: () => import('@/views/login/login'),
	//   hidden: true
	// },
	
	// {
	//   path: '/loginMobile',
	//   component: () => import('@/views/login/loginMobile'),
	//   hidden: true
	// },
	
	
	// {
	//   path: '/regist',
	//   component: () => import('@/views/regist/regist'),
	//   hidden: true
	// },
	// {
	//   path: '/registMobile',
	//   component: () => import('@/views/regist/registMobile'),
	//   hidden: true
	// },
	{
		path: '/',
		name: 'layout',
		component: layout,
		redirect:'/home',
		children: [
			{
				path: '/home',
				meta: {
					isLogin: true
				},
				name: 'home',
				component: home,
			}, 
			{
				path: '/homeMobile',
				meta: {
					isLogin: false
				},
				name: 'homeMobile',
				component: homeMobile
			}, 
	
		]
	},

]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
